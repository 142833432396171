<template>
  <div>
    <div v-if="!iframeSrc">
      <form class="mx-0 flex flex-wrap" @submit.prevent="createChannel">
        <div class="w-[65%] flex-shrink-0 flex-grow-0 max-w-[65%]">
          <label :class="{ error: $v.phoneNumber.$error }">
            {{ $t('INBOX_MGMT.ADD.WHATSAPP.PHONE_NUMBER.LABEL') }}
            <input
              v-model.trim="phoneNumber"
              type="text"
              :placeholder="
                $t('INBOX_MGMT.ADD.WHATSAPP.PHONE_NUMBER.PLACEHOLDER')
              "
              @blur="$v.phoneNumber.$touch"
            />
            <span v-if="$v.phoneNumber.$error" class="message">
              {{ $t('INBOX_MGMT.ADD.WHATSAPP.PHONE_NUMBER.ERROR') }}
            </span>
          </label>
        </div>

        <div class="w-full">
          <woot-submit-button
            :loading="isIframe"
            :button-text="$t('INBOX_MGMT.ADD.WHATSAPP.SUBMIT_BUTTON')"
          />
        </div>
      </form>
    </div>
    <div v-else class="w-full">
      <iframe
        ref="QR"
        @load="iframeLoadHelper"
        width="100%"
        height="340"
        :src="iframeSrc"
        title="QR Code"
        frameborder="0"
        scrolling="no"
      />
    </div>
  </div>
</template>

<script>
/* global axios */
import { mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';
import { required } from 'vuelidate/lib/validators';
import Spinner from 'shared/components/Spinner.vue';
import { isPhoneE164OrEmpty } from 'shared/helpers/Validators';
import router from '../../../../index';

export default {
  mixins: [alertMixin],
  components: {
    Spinner,
  },
  data() {
    return {
      iframeSrc: '',
      isIframe: false,
      phoneNumber: '',
      iframeReady: false,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
      currentUserId: 'getCurrentUserID',
      uiFlags: 'inboxes/getUIFlags',
      globalConfig: 'globalConfig/get',
    }),
  },
  watch: {
    currentUserId(newCurrentUserId, prevCurrentUserId) {
      if (prevCurrentUserId !== newCurrentUserId) {
        this.initializeUser();
      }
    },
  },
  mounted() {
    if (this.currentUserId) {
      this.initializeUser();
    }
  },
  validations: {
    phoneNumber: { required, isPhoneE164OrEmpty },
  },
  updated() {
    this.iframeReady = this.iframeSrc;
  },
  methods: {
    initializeUser() {
      this.access_token = this.currentUser.access_token;
    },
    iframeLoadHelper() {
      if (this.iframeReady) return this.iframeLoaded();
      else return; // do nothing
    },
    // The real load method
    iframeLoaded() {
      //console.log('iframe loaded');
    },
    checkStatus(url) {
      let interval = setInterval(
        () =>
          axios.get(url + '/check').then(res => {
            if(res.data.status === 'connected'){
              clearInterval(interval);
              router.replace({
                name: 'settings_inboxes_add_agents',
                params: {
                  page: 'new',
                  inbox_id: res.data.inboxId,
                },
              });
              axios
                .post('https://qr.chat365.com.tr/updateInbox', {
                  session: this.phoneNumber.replace(/[^0-9.]/g, ''),
                  token: this.access_token,
                  inboxId: res.data.inboxId,
                  email: this.currentUser.email,
                  account: this.currentUser.account_id,
                })
                .then(response => {
                  //console.log(response);
                });
            }
          }),
        10000
      );
      setTimeout(() => clearInterval(interval), 300000);
    },
    setIframe(url) {
        setTimeout(() =>
            axios.get(url)
                .then(() => {
                    this.iframeSrc = url
                    this.checkStatus(url)
                }), 1000
        );
    },
    async createChannel() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.isIframe = true;
      try {
        await axios
          .post(`https://qr.chat365.com.tr/qrcode`, {
            session: this.phoneNumber.replace(/[^0-9.]/g, ''),
            token: this.access_token,
            email: this.currentUser.email,
            account: this.currentUser.account_id,
            isForceScan: true,
          })
          .then(response => {
            this.setIframe(response.data.url);
          });
      } catch (error) {
        this.showAlert(this.$t('INBOX_MGMT.ADD.WHATSAPP.API.ERROR_MESSAGE'));
      }
    },
  },
};
</script>
